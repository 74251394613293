.ol-legend-info {
    display: none;
    top: 12em;
    left: .5em;
    /*background-color: white;*/
    /*width: 15em;*/
    padding: 0.5em;
    font-size: smaller;
}

.ol-legend-info-show{
    display: block;
}

@media only screen and (max-width: 600px) {
    .ol-legend-info {
        display: none;
    }
    .ol-legend-info-show{
        display: none;
    }
}
