.ol-custom-add-sources {
    max-width: 25em;
    top: 3.5em;
    left: .5em;
    z-index: 100;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    cursor: default;
}

.ol-custom-add-sources #add_sources_div{
    left: 2.5em;
    top: -1.5em;
    padding: 0.5em;
}

.ol-custom-add-sources #add_sources_button{
    margin: 0.2em;
    width: calc(100% - 0.3em);
}

.ol-custom-add-sources #add_sources_get_layers_button{
    margin: 0.2em;
    width: calc(100% - 0.3em);
}

.ol-custom-add-sources #add_sources_select{
    margin: 0.2em;
    width: calc(100% - 0.3em)
}

.ol-custom-add-sources #add_sources_input{
    margin: 0.2em;
    width: calc(100% - 0.3em)
}

.ol-custom-add-sources label{
    margin: 0.2em;
    width: 25em;
    color: black;
    font-weight: bold;
    font-size: larger;
}