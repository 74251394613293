.ol-custom-swipe {
    /*max-width: 36em;*/
    top: .5em;
    left: .5em;
    z-index: 200;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    cursor: default;
}

.ol-custom-swipe #swipe_selection_layers_div{
    left: 2.5em;
    top: -1.5em;
    padding: 0.5em;
}

.ol-custom-swipe #swipe_selection_layers_div_layer_left{
    float: left;
}

.ol-custom-swipe #swipe_selection_layers_div_layer_right{
    float: right;
}

.ol-custom-swipe select{
    padding: 0.2em;
    margin: 0.2em;
}

.ol-custom-swipe #swipe_compare_button{
    margin: 0.2em;
    width: calc(100% - 0.3em);
}

.ol-custom-swipe #wipe_selection_layers_label{
    width: 18.5em;
}

.ol-custom-swipe #wipe_selection_date_label{
    width: 11.5em;
}

.ol-custom-swipe #swipe_selection_layer_1{
    width: 21em;
}

.ol-custom-swipe #swipe_selection_layer_2{
    width: 21em;
}

.ol-custom-swipe #swipe_layer_date_1{
    width: 9em;
}

.ol-custom-swipe #swipe_layer_date_2{
    width: 9em;
}

.ol-custom-swipe #swipe_layer_time_1{
    width: 3.5em;
}

.ol-custom-swipe #swipe_layer_time_2{
    width: 3.5em;
}

.ol-custom-swipe label{
    width: 15em;
    text-align: center;
    background-color: rgba(65,65,66,1);
    color: white;
    font-weight: 600;
    font-size: initial;
}

