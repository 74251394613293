.utci-div{
    position: relative;
    height: 150px;
    width: 150px;
    /*border-style: solid;*/
    /*border-width: 0.5px;*/
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    border-radius: 20px;
    align-items: center;
    display: table-cell;
    vertical-align: middle;
}

.utci-div-title{
    font-size: larger;
    font-weight: bold;
    position: absolute;
    top: 1em;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, 50%);
}

.utci-div-timestamp{

}

.utci-div-value{
    font-size: xx-large;
    font-weight: bolder;
    position: absolute;
    top: 0.7em;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, 50%);
}
.utci-div-value span{
    font-size: 0.5em;
}

.utci-div-value-text{
    font-weight: bolder;
    position: absolute;
    top: 4.5em;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, 50%);
}

.utci-div-div{
    float: left;
    width: 100%
}
.utci-0{
    background-color: #a0a0a0;
    opacity:0.5;
    margin: 10px;
}
.utci-1{
     background-color: #0000C0;
     margin: 10px;
    color: white;
 }
.utci-2{
    background-color: #0000FF;
    margin: 10px;
    color: white;
}
.utci-3{
    background-color: #0060FF;
    margin: 10px;
}
.utci-4{
    background-color: #00C0FF;
    margin: 10px;
}
.utci-5{
    background-color: #00C000;
    margin: 10px;
}
.utci-6{
    background-color: #FF6600;
    margin: 10px;
}
.utci-7{
    background-color: #FF3300;
    margin: 10px;
}
.utci-8{
    background-color: #CC0000;
    margin: 10px;
    color: white;
}
.utci-div-left{
    font-size: smaller;
    text-align: left;
    display: inline-block;
    width: 50%;
    height: 20%;
}.utci-div-right{
     font-size: smaller;
     text-align: right;
     display: inline-block;
     width: 50%;
     height: 20%;
 }
.utci-div-1{
    text-align: center;
    background-color: #0000C0;
    display: inline-block;
    width: 12.5%;
    height: 20%;
}
.utci-div-2{
    text-align: center;
    background-color: #0000FF;
    display: inline-block;
    width: 12.5%;
    height: 20%;
}
.utci-div-3{
    text-align: center;
    background-color: #0060FF;
    display: inline-block;
    width: 12.5%;
    height: 20%;
}
.utci-div-4{
    text-align: center;
    background-color: #00C0FF;
    display: inline-block;
    width: 12.5%;
    height: 20%;
    color: white;
}
.utci-div-5{
    text-align: center;
    background-color: #00C000;
    display: inline-block;
    width: 12.5%;
    height: 20%;
    color: white;
}
.utci-div-6{
    text-align: center;
    background-color: #FF6600;
    display: inline-block;
    width: 12.5%;
    height: 20%;
    color: white;
}
.utci-div-7{
    text-align: center;
    background-color: #FF3300;
    display: inline-block;
    width: 12.5%;
    height: 20%;
    color: white;
}
.utci-div-8{
    text-align: center;
    background-color: #CC0000;
    display: inline-block;
    width: 12.5%;
    height: 20%;
    color: white;
}
