.ol-custom-print {
    max-width: 25em;
    top: 6.75em;
    left: .5em;
    z-index: 50;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    cursor: default;
}

