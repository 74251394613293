.dashboardTabPane-vertical-center{
    /*margin: auto;*/
    /*width: 100px;*/
    /*height: 100px;*/
    text-align: justify;
}

.dashboardTabPane-horizontal-center{
    margin: 0 auto;
    width: 3em;
    font-weight: bold;
    font-size: x-large;
}